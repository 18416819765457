import axios from "./request";
// 获取试听课程详情
export function getMoreTrialCourseDetailApi(data){
    return axios({
        url: `security-training/app/audition/getAuditionContentByAuditionId`,
        method: "POST",
        data: data,
    });
}
// 获取试听课程列表（点击更多）
export function getMoreTrialCourseListApi(data){
    return axios({
        url: `security-training/app/audition/getAuditionDetailForCourse`,
        method: "POST",
        data: data,
    });
}

// 首页轮播图
export function banner(data) {
    return axios({
        url: `security-training/app/banner/list`,
        method: "POST",
        data: data,
    });
}
// 培训课程列表
export function courseListWeb(data) {
    return axios({
        url: `security-training/app/course/list`,
        method: "POST",
        data: data,
    });
}
//课程内容详细接口

export function courseContentWeb(contentId) {
    return axios({
        url: `security-training/app/course/courseContentWeb?contentId=${contentId}`,
        method: "GET",
    });
}

// 首页培训课程试听
export function auditionList(data) {
    return axios({
        url: `security-training/app/audition/list`,
        method: "POST",
        data: data,
    });
}
// 视频列表接口(废弃)
export function auditionContentList(data) {
    return axios({
        url: `security-training/app/audition/content/list`,
        method: "POST",
        data: data,
    });
}
// 视频列表接口(废弃)
export function auditionContentList2(id) {
    return axios({
        url: `security-training/app/commonwealVideo/info/${id}`,
        method: "GET"
    })
}
// 公益列表接口
export function commonwealList(data={}) {
    return axios({
        url: `security-training/app/commonweal/list`,
        method: "POST",
        data:data,

    });
}
// 公益内容接口
export function commonwealContentList(commonwealId) {
    return axios({
        url: `security-training/app/commonweal/content/list?commonwealId=${commonwealId}`,
        method: "POST",
    });
}
// 直播列表接口
export function broadcastList() {
    return axios({
        url: `security-training/app/course/broadcast/list`,
        method: "POST",
    });
}
//直播接口
export function broadcast(broadcastmanageId) {
    return axios({
        url: `security-training/app/course/broadcast?broadcastmanageId=${broadcastmanageId}`,
        method: "POST",
    });
}

//联系我们
export function guidegetLxwm() {
    return axios({
        url: `security-training/app/guide/getLxwm`,
        method: "GET",
    });
}


//练习列表接口

export function exerciseList() {
    return axios({
        url: `security-training/app/exercise/list`,
        method: "POST",
    });
}
//练习详细接口
export function exerciseget(exerciseId) {
    return axios({
        url: `security-training/app/exercise/get/${exerciseId}`,
        method: "POST",
    });
}

//获取练习卷接口
export function exercisegetPaperFromWeb(exerciseId) {
    return axios({
        url: `security-training/app/exercise/getPaperFromWeb/${exerciseId}`,
        method: "POST",
    });
}

//提交（练习）答案接口

export function exercisesubmitAnswer(data) {
    return axios({
        url: `security-training/app/exercise/submitAnswer/`,
        method: "POST",
        data:data
    });
}
//错题(测试)接口

export function exercisemistakeQuestionList(exerciseId) {
    return axios({
        url: `security-training/app/exercise/mistakeQuestionList/${exerciseId}`,
        method: "POST",
    });
}


//行业新闻详细接口
export function articleDetail(articleId) {
    return axios({
        url: `security-training/app/article/detail/${articleId}`,
        method: "GET",
    });
}
//文章详细接口
export function articleDetail1(articleID,interviewFlag) {
    return axios({
        url: `security-training/app/article/detail/${articleID}/${interviewFlag}`,
        method: "GET",
    });
}

//行业新闻列表接口
export function articleList(data) {
    return axios({
        url: `security-training/app/article/list`,
        method: "POST",
        data:data
    });
}
//下载中心接口
export function attachmentList(data) {
    return axios({
        url: `security-training/app/attachment/list`,
        method: "POST",
        data:data
    });
}

//学习指南

export function guidegetContent(data) {
    return axios({
        url: `security-training/app/guide/list`,
        method: "POST",
        data:data
    });
}
// 学习指南详情
export function getGuideDetailApi(data){
    return axios({
        url:'security-training/app/guide/getListById' + '?id=' + data,
        method: "POST",
    })
}
//通知公告接口
export function noticeList(data) {
    return axios({
        url: `security-training/app/notice/list`,
        method: "POST",
        data:data
    });
}


//通知公告详细接口
export function noticeDetail(noticeId) {
    return axios({
        url: `security-training/app/notice/content/list?noticeId=${noticeId}`,
        method: "POST"
    });
}

//能力测评接口(老)
export function coursenewListWeb(data) {
    return axios({
        url: `security-training/app/coursenew/list`,
        method: "POST",
        data: data,
    });
}

//能力测试详细接口
export function courseContentWebget(contentId) {
    return axios({
        url: `security-training/app/coursenew/courseContentWeb/contentId=${contentId}`,
        method: "POST",
    });
}



//登录接口
export function Login(data){
    return axios({
        url:`security-training/app/login`,
        method:"POST",
        data:data
    })
}

//修改用户信息接口
export function userupdate(data){
    return axios({
        url:`security-training/app/user/update`,
        method:"POST",
        data:data
    })
}
//获取用户信息
export function getUserInfo(){
    return axios({
        url:`security-training/app/user/getUserInfo`,
        method:"GET"
    })
}

//用户修改密码

export function updatePassword(data){
    return axios({
        url:`security-training/app/user/updatePassword`,
        method:"POST",
        data:data
    })
}

//用户头像上传接口

export function uploadHeadImg(data){
    return axios({
        url:`security-training/app/user/uploadHeadImg`,
        method:"POST",
        headers: {
                'Content-Type': 'multipart/form-data',
                "Accept": "*/*",
              },
        data:data
    })
}


//未读消息数

export function messageunread(){
    return axios({
        url:`security-training/app/message/unread`,
        method:"GET",
    })
}

//消息列表
export function messagelist(data){
    return axios({
        url:`security-training/app/message/list`,
        method:"POST",
        data:data
    })
}

//消息内容接口
export function messageread(messageId) {
    return axios({
        url: `security-training/app/message/read/${messageId}`,
        method: "POST",
    });
}
// 专家库接口
export function getExpertBaseApi() {
    return axios({
        url: `security-training/app/expert/list`,
        method: "POST",
    });
}
// 专家库详情接口
export function getExpertDetailApi(expertId) {
    return axios({
        url: `security-training/app/expert/detail/${expertId}`,
        method: "GET",
    });
}
// 公益宣传视频文章职业分类 '01'公益 ‘02’培训认证
export function professionalClassificationApi(data) {
    return axios({
        url: `security-training/app/category/getCategoryList`+'?moduleType=' + data,
        method: "POST",
    });
}
// 公益宣传首页未登录可看视频
export function getPublicVideoIndexApi(data){
    return axios({
        url:'security-training/app/commonwealVideo/getCommonwealVideoListHomePage',
        method:"POST",
        data:data
    })
}

// 特定职业公益宣传视频list
export function getPublicVideoSortApi(data){
    return axios({
        url:`security-training/app/commonwealVideo/getCommonwealVideoList `,
        method:"POST",
        data:data
    })
}
// 特定职业公益宣传文章list
export function getPublicEssaySortApi(data){
    return axios({
        url:`security-training/app/commonweal/getCommonwealList`,
        method:"POST",
        data:data
    })
}
// 注册发送验证码
export function sendMsgApi(data){
    return axios({
        url:'security-training/app/sendRegisterCode'+'?phone=' + data,
        method:"POST",
    })
}
// 注册
export function registrationApi(data){
    return axios({
        url:'security-training/app/register',
        method:"POST",
        data:data
    })
}
// 登录发送验证码
export function loginSendMsgApi(data){
    return axios({
        url:'security-training/app/sendLoginCode' + '?phone=' + data,
        method:"POST"
    })
}
// 登录接口
export function loginApi(data){
    return axios({
        url:'security-training/app/login',
        method:'POST',
        data:data,
    })
}
//培训视频查询
export function trainingVideoList (data){
    return axios({
        url:'security-training/app/course/getCourseByCatagoryCode',
        method:'POST',
        data:data,
    })
}
//培训视频详情?courseId=${data}
export function getCourseContentByCourseId (data){
    return axios({
        url:`security-training/app/course/getCourseContentByCourseId`,
        method:'POST',
        data:data,
    })
}

//习题列表
export function getExerciseList (data){
    return axios({
        url:`security-training/app/exercise/getList`,
        method:'POST',
        data:data,
    })
}
// 判断是否做过练习
export function isExerciseDoneApi (data){
    return axios({
        url:`security-training/app/exerciseAnswer/getCount`,
        method:'POST',
        data:data,
    })
}
// 已练习练习题详情回显接口
export function haveDoneExerciseDetailApi (data){
    return axios({
        url:'security-training/app/exercise/getExerciseDoneById' + '?id=' + data,
        method:'POST',
    })
}
//在线报名课程一级菜单lsit
export function getOnlineRegistMenuApi (data){
    return axios({
        url:'security-training/app/category/getCategoryList' + '?moduleType=' + data,
        method:'POST',
    })
}
// 在线报名课程二级菜单list
export function getOnlineRegistSecondMenuApi (data){
    return axios({
        url:'security-training/app/signup/list',
        method:'POST',
        data:data
    })
}
//在线报名课程二级菜单详情
export function getOnlineRegistSecondMenuDetailApi (data){
    return axios({
        url:'security-training/app/signupNotice/getDataBySignupNoticeId' + '?signupNoticeId=' + data,
        method:'POST',
    })
}
// 在线报名‘注册’步骤表单提交
export function saveOnlineRegistStepTwoFormApi (data){
    return axios({
        url:'security-training/app/signupDetail/save',
        method:'POST',
        data:data
    })
}
// 通用上传图片接口
export function uploadImgApi (data){
    return axios({
        url:'security-training/app/upload/image',
        headers: {
            'Content-Type': 'multipart/form-data',
            "Accept": "*/*",
            'token':'',
          },
        method:'POST',
        data:data
    })
}
// 判断是否待缴费来决定跳转注册第几步
export function isPayRegsitApi (data){
    return axios({
        url:'security-training/app/signupDetail/getSignup',
        method:'POST',
        data:data
    })
}
// 图片上传更新状态’02‘已缴费状态
export function updateUploadImgUrlApi(data) {
    return axios({
        url: 'security-training/app/signupDetail/update',
        data: data,
        method:'POST',
    })
}
// 能力测评模拟考卷List
export function getImitateTestListApi(data){
    return axios({
        url:'security-training/app/exam/getSimulationExam',
        data:data,
        method:'POST'
    })
}
// 判断能力测评模拟考卷是否已提交已做过来决定是否回显
export function isWriteImtateTestStatusApi(data){
    return axios({
        url:'security-training/app/exam/getCount',
        data:data,
        method:'POST'
    })
}

// 获取能力测评模拟考卷未做题试卷详情
export function getImtateTestInitDetailApi(id){
    return axios({
        url:'security-training/app/exam/getPaper/'+ id,
        method:'POST'
    })
}
// 提交能力测试答题结果提交
export function commitImtateTestResApi(data){
    return axios({
        url:'security-training/app/exam/submitAnswer',
        method:'POST',
        data
    })
}
//能力测评已答题试卷和答题对错回显
export function getShowImtateTestDoneDetailApi(id){
    return axios({
        url:'security-training/app/exam/getExamDoneById?id=' + id,
        method:'POST'
    })
}
//查询是否已报名
export function getUserIsSignup(data){
    return axios({
        url:'security-training/app/signup/getUserIsSignup',
        method:'POST',
        data:data
    })
}

//查询轮播详情
export function getExamDoneById(id){
    return axios({
        url:`security-training/app/banner/${id}`,
        method:'get',
    })
}


//查询网站信息
export function getWebinformation(){
    return axios({
        url:`security-training/app/webinformation/list`,
        method:'get',
    })
}

//查询联系我们
export function getContactus(){
    return axios({
        url:`security-training/app/contactus/list`,
        method:'get',
    })
}


//查询网站信息
export function getFriendlylink(){
    return axios({
        url:`security-training/app/friendlylink/list`,
        method:'get',
    })
}

//压缩图片
export function downloadIamges(data){
    return axios({
        url:'security-training/app/user/downloadIamges',
        method:'POST',
        data,
        responseType: "blob",
    })
}



//获取证书
export function getCertificateList(data){
    return axios({
        url:'security-training/app/user/certificateList',
        method:'POST',
        data:data
    })
}
// 培训认证我的课程、习题练习
export function getMycourseAndExcriseTestApi(data){
    return axios ({
        url:'security-training/app/course/getAppUserDetailForCourse',
        method:'POST',
        data:data
    })
}
// 培训认证我的习题接口
export function getMyExcriseTestApi(data){
    return axios ({
        url:'security-training/app/exercise/getAppUserDetailForExercise',
        method:'POST',
        data:data
    })
}
//获取公益宣传轮播
export function getCommonweallist(data){
    return axios ({
        url:'security-training/app/banner/commonweallist',
        method:'POST',
        data:data
    })
}


//获取联系我们接口
export function getCblist(){
    return axios ({
        url:'security-training/app/contactus/cblist',
        method:'GET',
    })
}
// 协议相关
export function getAgreementAboutApi(data){
    return axios({
        url:'security-training/app/signupAgreement/list',
        data,
        method:'POST',
    })
}
// 提交课程视频进度百分比
export function commitCourseProgressApi(data){
    return axios({
        url:'security-training/app/courseContent/watchVideo',
        data,
        method:'POST'
    })
}
// 查询课程下所有视频list列表进度百分比
export function getCourseProgressApi(data){
    return axios({
        url:'security-training/app/course/getCourseContentByCourseId',
        data,
        method:'POST'
    })
}
