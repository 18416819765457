<template>
  <div id="app">
		<keep-alive>
    <router-view v-if="$route.meta.keepAlive" :key="fullpath" ></router-view>
		</keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="fullpath"></router-view>
		<Topback/>
		<customService v-if="$route.path == '/home'"></customService>
  </div>
</template>

<script>
import Topback from './components/Topback.vue';
import customService from './components/customService.vue';
export default {
  name: "App",
  components:{
    Topback,
		customService
  },
	data(){
		return { 
		getKeepAliveViewStr:this.$store.state.getKeepAliveViewStr,
		}
	},
	computed: {
    fullpath: function() {
      return this.$route.query.publicProssId;
    }
  },

	watch:{
		'$route':{
			handler(newVal){
				console.log('newVal',newVal.name)
			},
			deep:true,
			immediate: true // 立即处理 进入页面就触发
		}
	}
};
</script>

<style lang="scss">
html {
  overflow-y: overlay;
}
body,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0px;
}

.flex-row {
  display: flex;
}

.jc-c {
  justify-content: center;
}

.jc-sa {
  justify-content: space-around;
}

.jc-sb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.fdc {
  flex-direction: column;
}

.fww {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

/* 超出显示省略号 */
.overflow1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 超出2行显示省略号 */
.overflow2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.overflow3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
a {
  text-decoration: none;
  background: transparent;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

.ovAuto {
  overflow: overlay;
}
.flex {
		display: flex;
	}
.flex-wrap {
		flex-wrap: wrap;
	}

	.flex-nowrap {
		flex-wrap: nowrap;
	}
.flex-row {
	flex-direction: row;
}
.flex-column {
		flex-direction: column;
	}

	.flex-row-reverse {
		flex-direction: row-reverse;
	}

	.flex-column-reverse {
		flex-direction: column-reverse;
	}

	.flex-align-center {
		align-items: center;
	}
.flex-align-start {
		align-items: start;
	}

	.flex-align-end {
		align-items: flex-end;
	}

	.flex-jus-between {
		justify-content: space-between;
	}

	.flex-jus-center {
		justify-content: center;
	}
.flex-jus-around {
		justify-content: space-around;
	}

	.flex-jus-end {
		justify-content: flex-end;
	}
.flex-between-center {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.flex-self-end {
		align-self: flex-end;
	}
.no-wrap {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

.no-wrap-2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
  body>.el-menu--horizontal{
    background-color: #ffffff !important;
		// left: 142px !important;
		// top:250px !important;
  }
	body>.el-menu--horizontal >ul{
    background-color: #ffffff !important;
		min-width:157px !important;
  }
	body>.el-menu--horizontal >ul >li{
    background-color: #ffffff !important;
		color: RGB(108,115,132) !important;
		text-align: center ;
		min-width: 151px !important;
  }
	body>.el-menu--horizontal >ul >li:hover{
    background-color: #686464 !important;
		color:#ffffff !important;	
  }
	body>.el-pagination.is-background .btn-next,.el-pagination.is-background .btn-prev,.el-pagination.is-background .btn-next:disabled {
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 4px !important;
    padding: 0 12px;
}
.nothing-view{
	margin-top:20px;
}
body .el-pager li:not(.disabled).active{
	background-color: #C30A1B !important;
}
body .el-pager li:not(.disabled):hover{
	color: #C30A1B !important;
}
body .el-pager li:not(.disabled){
	// color: #C30A1B !important;
	background-color: #FFFFFF !important;
	border: 1px solid #E5E5E5;
	border-radius: 4px;
}
.emptyText{
	font-size: 20px !important;
	font-weight: 400 !important;
	color: #B9BEC1 !important;
}
div{
	user-select: none;
	
}
body .el-button--primary{
	background-color: rgb(219,7,20) !important;
	border: none;
}
body .el-button--primary:hover{
	background-color: rgb(197,1,18) !important;
	border: none;

}
body .el-button--primary.is-plain{
	color: #FFFFFF !important;
		border: none;

}
body .el-button:focus, .el-button:hover{
	color:#FFFFFF !important;
    border-color: #c6e2ff !important;
    background-color: rgb(197,1,18) !important;
}
.commit-test-class{
	margin-bottom: 100px	;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #c30a1b;
  border-color: #c30a1b;
}

</style>
