
   function formateTime(time) {
        const h = parseInt(time / 3600)
        const minute = parseInt(time / 60 % 60)
        const second = Math.ceil(time % 60)
    
        const hours = h < 10 ? '0' + h : h
        const formatSecond = second > 59 ? 59 : second
        return `${hours > 0 ? `${hours}:` : ''}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
    }
    //截取日期年月日
  function  getYearMonth(date){
        // 将日期以空格隔开，即['2020-06-13', '17:10:09']
        date = (date + '').split(/[ ]+/);
        let result = [];
        let reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
        // 用截取出来的年月日进行正则表达式匹配
        reg.exec(date[0]);
        result.push(RegExp.$1);	//获取匹配到的第一个子匹配，即‘2020’
        result.push(RegExp.$2);
        result.push(RegExp.$3);
        return result[0] + '-' + result[1] + '-'  +  result[2] ;
    }
    function  getYearMonth2(date){
      // 将日期以空格隔开，即['2020-06-13', '17:10:09']
      date = (date + '').split(/[ ]+/);
      let result = [];
      let reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
      // 用截取出来的年月日进行正则表达式匹配
      reg.exec(date[0]);
      result.push(RegExp.$1);	//获取匹配到的第一个子匹配，即‘2020’
      result.push(RegExp.$2);
      result.push(RegExp.$3);
      return result[0] + '/' + result[1] + '/'  +  result[2] ;
  }

    function transNumber (i) {
      const dist = {
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
        5: 'E',
        6: 'F',
        7: 'G',
        8: 'H',
        9:'I',
        10: 'J',
        11: 'K',
        12: 'L',
        13: 'M',
        14: 'N',
        15: 'O',
        16: 'P',
        17: 'Q',
        18: 'R',
        19: 'S',
        20: 'T',
        21: 'U',
        22: 'V',
        23: 'W',
        24: 'X',
        25: 'Y',
        26: 'Z',
      }
      return dist[i] || ''
    }
    export {
        formateTime,
        getYearMonth,
        getYearMonth2,
        transNumber,
      }
