import Vue from "vue";
import axios from "axios";
import store from "../store/index.js"
import router from '../router/index';

// 导入store
let env = process.env.NODE_ENV;
let baseURL;
// 开发环境
if (env === "development") {
    baseURL = "/api";
    // baseURL = "http://43.138.196.150:8055/";
    // baseURL = "http://192.168.0.111:8055/";
    // baseURL = "http://192.168.2.10:8055/";
} else {
    // baseURL = "http://114.115.219.224/";
    // baseURL = "http://admin.xjxfpx.com/"
    baseURL = "https://xjxfpx.com/"
    // baseURL = "/api";
}


const service = axios.create({
    // 如果换了新项目，需要更换为新的接口地址
    baseURL: baseURL,
    timeout: 50000, // 请求超时时间(因为需要调试后台,所以设置得比较大)
});

// request 对请求进行拦截
service.interceptors.request.use(
    (config) => {
        // 开启loading
        // localStorage.setItem('token', "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjczODU4OTAxLCJleHAiOjE2NzY0NTA5MDF9.nBSNzlDkuGmKvVeWyVESO1xnGhthTtx5kiAf76YdjTHLfcqdit2HhIfs3vBObrYB5_uGwWW7t1ckRG29fUpECA")
        // 请求头添加token
        // config.headers.token = localStorage.getItem('token');
        config.headers['Token'] = Vue.$cookies.get("token");
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// response 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        if(res.code == 401){
            // Vue.prototype.$message(res.msg)
            // Vue.prototype.$message('建议登录状态操作')
            Vue.$cookies.remove("user");
            Vue.$cookies.remove("token");
            store.state.user = {};
            store.state.isLogin = false;
    //          if(!store.state.isLogin){
    //   store.commit("login");
    // }
            // router.push("/");
    //   this.$Message.warning("已退出登录！");
            return res
        }
        if(res.code == 500){
            Vue.prototype.$message(res.msg)
            return res
        }
        if (response.status === 200) {
            return res
        }
       
    },
    error=> {
        // 跟后台连接失败
        console.log("报错", error);
        return Promise.resolve(error)
        // return error
    }
);

export default service;