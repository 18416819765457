
<template>
  <div
    class="right_fbox goTop fixBg flex flex-column flex-algin-center flex-jus-center flex-nowarp"
  >
    <div class="rightFixBtn" @click="onlineEnroll">
      <div class="flex flex-column flex-jus-center flex-algin-center">
        <div class="enrollImgClass" style="margin-left:15px">
          <img class="enrollImgClass" src="@/assets/enrollIcon.png" alt="" />
        </div>
        <div class="check_link onlineEnrollClass">在线报名</div>
      </div>
    </div>
    <div class="rightFixBtn2"  style="position: relative;" @mouseenter="handleShow" @mouseleave="handleShow">
      <div class="flex flex-column flex-jus-center flex-algin-center">
        <div class="enrollImgClass" style="margin-left:15px">
          <img class="enrollImgClass" src="@/assets/content.png" alt=""  />
          <div></div>
        </div>
        <div class="check_link onlineEnrollClass">联系我们</div>
      </div>
    </div>
    <!-- <div class="rightFixBtn" @click="onlineEnroll">
      <div class="flex flex-column flex-jus-center flex-algin-center">
        <div class="enrollImgClass" style="margin-left:15px">
          <img class="enrollImgClass" src="@/assets/zixun.png" alt="" />
        </div>
        <div class="check_link onlineEnrollClass">在线咨询</div>
      </div>
    </div> -->
    <div v-if="imgShow" class="icon-div">
      <div v-if="contarct.content" class="icon-text">{{contarct.content}}</div>
      <img :src='contarct.imgUrl' class="icon-img" v-if="contarct.imgUrl"/>
    </div>
  </div>
</template>

<script>
import { getCblist } from "@/api/index";
export default {
  name: "customService",
  props: {},
  data() {
    return {
      imgShow:false,
      contarct:{}
    };
  },
  mounted() {
    this.handleCblist()
  },
  methods: {
    // 打开在线咨询
    openTalk() {
      window._MEIQIA("showPanel");
    },
    // 在线报名
    onlineEnroll() {
      this.$router.push({ path: "/onlineCourse" });
    },  
    handleShow(){
      this.imgShow=!this.imgShow
    },
    async handleCblist(){
      const resp=await getCblist()
      if(resp.code==0){
        this.contarct=resp.data
      }
    }
  },
};
</script>

<style scoped lang="scss">
.download_and_study {
  width: 1160px;
  margin: 0 auto;
}
.topIndex {
  .top {
    position: fixed;
    width: 30px;
    height: 30px;
    bottom: 50px;
    right: 100px;
    background-color: aqua;
  }
}

.goTop {
  position: fixed;
  right: 0px;
  bottom: 160px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right_fbox {
  width: 143px;
  z-index: 999;
  width: 72px;
  height: 200px;
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(186, 186, 186, 0.3);
  border-radius: 30px 0px 0px 30px;
  .rightFixBtn {
    // padding: 10px 10px;
    margin-bottom: 0px;
    // padding-left: 20px;
  }
  .rightFixBtn2 {
    // padding: 10px 10px;
    margin-top: 10px;
    // padding-left: 20px;
  }
  .rightFixBtn2 {
    margin-top: 40px;
  }
}
.fixBg {
  // background-color: #244469;
  .enrollImgClass {
    width: 32px;
    height: 32px;
  }
}
.check_link {
  // background-color: #0163a9;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.onlineEnrollClass {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  padding: 10px 0 0 0;
}
.icon-img{
  width: 200px;
  height: 200px;
}
.icon-div{
  position: absolute;
  right: 100px;
  // box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.16);
}
.icon-text{
  font-size: 24px;
  font-weight: bold;
  color: rgb(51, 51, 51);
  text-align: center;
  margin-right: 20px;
}
</style>