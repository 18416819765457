import Vue from "vue";
import Router from "vue-router";
import Store from "@/store"

//解决路由跳转的时候两次push的path地址相同 报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 让router在vue起作用
Vue.use(Router);
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "home",
    path: "/home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      title: "首页",
      erify: false,
      level:1.5,
    },
  },
  {
    //能力测评
    name: "capabilityEvaluation",
    path: "/capabilityEvaluation",
    component: () => import("@/views/capabilityEvaluation/index.vue"),
    meta: {
      title: "能力测评",
      erify: false,
    },
  },
  {
    //能力测评--消防基础知识测试
    name: "aptitudetest",
    path: "/aptitudetest",
    component: () => import("@/views/capabilityEvaluation/aptitudetest.vue"),
    meta: {
      title: "消防基础知识测试",
      erify: false,
    },
  },
  {
    //能力测评已做过--消防基础知识测试
    name: "aptitudetestDone",
    path: "/aptitudetestDone",
    component: () => import("@/views/capabilityEvaluation/aptitudetestDone.vue"),
    meta: {
      title: "消防基础知识测试",
      erify: false,
    },
  },
  {
    //联系我们
    name: "contactUs",
    path: "/contactUs",
    component: () => import("@/views/contactUs/index.vue"),
    meta: {
      title: "联系我们",
      erify: false,
    },
  },
  {
    //下载中心
    name: "downloadCenter",
    path: "/downloadCenter",
    component: () => import("@/views/downloadCenter/index.vue"),
    meta: {
      title: "下载中心",
      erify: false,
    },
  },
  {
    //行业新闻
    name: "industryNews",
    path: "/industryNews",
    component: () => import("@/views/industryNews/index.vue"),
    meta: {
      title: "行业新闻",
      erify: false,
    },
  },
  {
    //行业新闻详情
    name: "industryNews",
    path: "/industryNews",
    component: () => import("@/views/industryNews/industryDetail.vue"),
    meta: {
      title: "行业新闻",
      erify: false,
    },
    redirect: "/industryDetail",
    children: [
      {
        path: "/industryDetail",
        name: "industryDetail",
        component: () => import("@/views/industryNews/industryDetail.vue"),
        meta: { title: "行业新闻详情", verify: true },
      },
    ],
  },
  {
    //通知公告
    name: "noticeAnnouncement",
    path: "/noticeAnnouncement",
    component: () => import("@/views/noticeAnnouncement/index.vue"),
    meta: {
      title: "通知公告",
      erify: false,
    },
  },
  {
    //公益宣传
    name: "publicWelfarePublicity",
    path: "/publicWelfarePublicity",
    component: () => import("@/views/publicWelfarePublicity/publicityPage.vue"),
    meta: {
      title: "公益宣传",
      erify: false,
    },
  },
  {
    //公益宣传
    name: "publicityPage",
    path: "/publicityPage",
    component: () => import("@/views/publicWelfarePublicity/publicityPage.vue"),
    meta: {
      title: "公益宣传文章",
      erify: false,
    },
  },
  {
    //试听课程
    name: "trialListen",
    path: "/trialListen",
    component: () => import("@/views/trialListen/index.vue"),
    meta: {
      title: "试听课程",
      erify: false,
    },
  },
  {
    //课程试听详情
    name: "trialListen",
    path: "/trialListen",
    component: () => import("@/views/publicWelfarePublicity/courseTestListenVideo.vue"),
    meta: {
      title: "试听课程",
      erify: false,
    },
    redirect: "/publicWelfarePublicity2",
    children:[
      {
        name: "publicWelfarePublicity2",
        path: "/publicWelfarePublicity2",
        component:()=> import('@/views/publicWelfarePublicity/courseTestListenVideo.vue'),
        meta: {
              title: "试听详情",
              erify: false,
            },
      }
    ]
  },

  //   //公益宣传详情一级
  // {
  //   name: "publicWelfarePublictyVideoPlay",
  //   path: "/publicWelfarePublictyVideoPlay",
  //   component: () =>
  //     import(
  //       "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //     ),
  //   meta: {
  //     title: "公益宣传视频播放",
  //     erify: false,
  //   },
  // },
  // {
  //   //公益宣传详情
  //   name: "publicWelfarePublicity",
  //   path: "/publicWelfarePublicity",
  //   component: () =>
  //     import(
  //       "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //     ),
  //   meta: {
  //     title: "公益宣传",
  //     erify: false,
  //   },
  //   redirect: "/wefareDetail",
  //   children: [
  //     {
  //       name: "publicWelfarePublictyVideoPlay",
  //       path: "/publicWelfarePublictyVideoPlay",
  //       component: () =>
  //         import(
  //           "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //         ),
  //       meta: {
  //         title: "公益宣传视频播放",
  //         erify: false,
  //       },
  //     },
  //   ],
  // },


  //  {
  //   //公益宣传详情
  //   name: "publicWelfarePublicity",
  //   path: "/publicWelfarePublicity",
  //   component: () =>
  //     import(
  //       "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //     ),
  //   meta: {
  //     title: "公益宣传",
  //     erify: false,
  //   },
  //   redirect: "/publicWelfarePublictyVideoPlay",
  //   children: [
  //     {
  //       name: "publicWelfarePublictyVideoPlay",
  //       path: "/publicWelfarePublictyVideoPlay",
  //       component: () =>
  //         import(
  //           "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //         ),
  //       meta: {
  //         title: "公益宣传视频播放",
  //         erify: false,
  //       },
  //     },
  //   ],
  // },
  
  {
    //公益宣传详情
    name: "publicWelfarePublicity",
    path: "/publicWelfarePublicity",
    component: () => import("@/views/publicWelfarePublicity/wefareDetail.vue"),
    meta: {
      title: "公益宣传",
      erify: false,
    },
    redirect: "/wefareDetail",
    children: [
      {
        name: "wefareDetail",
        path: "/wefareDetail",
        component: () =>
          import("@/views/publicWelfarePublicity/wefareDetail.vue"),
        meta: {
          title: "公益宣传文章",
          erify: false,
        },
      },
    
    ],
  },
  {
    //公益宣传详情
    name: "publicWelfarePublicity",
    path: "/publicWelfarePublicity",
    component: () => import("@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"),
    meta: {
      title: "公益宣传",
      erify: false,
    },
    redirect: "/publicWelfarePublictyVideoPlay",
    children: [
      {
        name: "publicWelfarePublictyVideoPlay",
        path: "/publicWelfarePublictyVideoPlay",
        component: () =>
          import("@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"),
        meta: {
          title: "公益宣传视频播放",
          erify: false,
        },
      },
    
    ],
  },
  {
    //学习指南
    name: "studyGuide",
    path: "/studyGuide",
    component: () => import("@/views/studyGuide/index.vue"),
    meta: {
      title: "学习指南",
      erify: false,
    },
  },
  {
    //学习指南详细
    name: "studyGuide",
    path: "/studyGuide",
    component: () => import("@/views/studyGuide/studyGuideDetail.vue"),
    meta: {
      title: "学习指南",
      erify: false,
    },
    redirect: "/studyGuideDetail",
    children: [
      {
        name: "studyGuideDetail",
        path: "/studyGuideDetail",
        component: () => import("@/views/studyGuide/studyGuideDetail.vue"),
        meta: {
          title: "学习指南详细",
          erify: false,
        },
      },
    ],
  },
  {
    //培训课程
    name: "trainingCourse",
    path: "/trainingCourse",
    component: () => import("@/views/trainingCourse/courseTrain.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
  },
  {
    //培训课程2
    name: "trainingCourse2",
    path: "/trainingCourse2",
    component: () => import("@/views/trainingCourse/courseTrain2.vue"),
    meta: {
      title: "培训课程",
      erify: false,
      keepAlive:true,
    },
  },
  {
    //培训课程
    name: "trainingCourse",
    path: "/trainingCourse",
    component: () => import("@/views/trainingCourse/trainingExercises.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect:'/trainingExercises',
    children:[
      {
        //习题练习
        name: "trainingExercises",
        path: "/trainingExercises",
        component: () => import("@/views/trainingCourse/trainingExercises.vue"),
        meta: {
          title: "习题练习",
          erify: false,
        },
      },
     
    ]
  },
  {
    //培训课程
    name: "trainingCourse2",
    path: "/trainingCourse2",
    component: () => import("@/views/trainingCourse/trainingExercises.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect:'/trainingExercises',
    children:[
      {
        //习题练习
        name: "trainingExercises",
        path: "/trainingExercises",
        component: () => import("@/views/trainingCourse/trainingExercises.vue"),
        meta: {
          title: "习题练习",
          erify: false,
        },
      },
     
    ]
  },
  {
    //培训课程
    name: "trainingExercises",
    path: "/trainingExercises",
    component: () => import("@/views/trainingCourse/trainingTestDone.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect:'/trainingExercisesDone',
    children:[
      {
        name: "trainingExercisesDone",
        path: "/trainingExercisesDone",
        component: () => import("@/views/trainingCourse/trainingTestDone.vue"),
        meta: {
          title: "已练习习题详情",
        },
      }
    ]
  },
  // {
  //   //培训课程
  //   name: "trainingCourse",
  //   path: "/trainingCourse",
  //   component: () => import("@/views/trainingCourse/trainingExercises.vue"),
  //   meta: {
  //     title: "培训课程",
  //     erify: false,
  //   },
  //   redirect:'/trainingExercises',
  //   children:[
  //     {
  //       //习题练习
  //       name: "trainingExercises",
  //       path: "/trainingExercises",
  //       component: () => import("@/views/trainingCourse/trainingExercises.vue"),
  //       meta: {
  //         title: "习题练习",
  //         erify: false,
  //       },
  //       redirect:'/trainingExercisesDone',
  //       children:[
  //         {
  //           name: "trainingExercisesDone",
  //           path: "/trainingExercisesDone",
  //           component: () => import("@/views/trainingCourse/trainingTestDone.vue"),
  //           meta: {
  //             title: "已练习习题详情",
  //           },
  //         }
  //       ]
  //     },
  //   ]
  // },
  // {
  //   //已练习题练习
  //   name: "trainingExercises",
  //   path: "/trainingExercises",
  //   component: () => import("@/views/trainingCourse/trainingTestDone.vue"),
  //   meta: {
  //     title: "习题练习",  
  //     erify: false,
  //   },
  //   redirect:'/trainingExercisesDone',
  //   children:[{
  //     name: "trainingExercisesDone",
  //     path: "/trainingExercisesDone",
  //     component: () => import("@/views/trainingCourse/trainingTestDone.vue"),
  //     meta: {
  //       title: "已练习习题详情",
  //     },
  //   }]
  // },
  {
    //培训课程 --- 消防基础知识
    name: "trainingCourse",
    path: "/trainingCourse",
    component: () => import("@/views/trainingCourse/basicKnowledge.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect: "/basicKnowledge",
    children: [
      {
        name: "basicKnowledge",
        path: "/basicKnowledge",
        component: () => import("@/views/trainingCourse/basicKnowledge.vue"),
        meta: {
          title: "视频详情",
          erify: false,
          level:1.5,
        },
      },
    ],
  },
  {
    //培训课程 --- 消防基础知识
    name: "trainingCourse2",
    path: "/trainingCourse2",
    component: () => import("@/views/trainingCourse/basicKnowledge.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect: "/basicKnowledge",
    children: [
      {
        name: "basicKnowledge",
        path: "/basicKnowledge",
        component: () => import("@/views/trainingCourse/basicKnowledge.vue"),
        meta: {
          title: "视频详情",
          erify: false,
          level:1.5,
        },
      },
    ],
  },
  {
    //培训课程 --- 消防基础知识测试
    name: "trainingCourse",
    path: "/trainingCourse",
    component: () => import("@/views/trainingCourse/trainingTest.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect: "/trainingTest",
    children: [
      {
        name: "trainingTest",
        path: "/trainingTest",
        component: () => import("@/views/trainingCourse/trainingTest.vue"),
        meta: {
          title: "消防基础知识测试",
          erify: false,
        },
      },
    ],
  },
  {
    //培训课程 --- 消防基础知识测试
    name: "trainingCourse2",
    path: "/trainingCourse2",
    component: () => import("@/views/trainingCourse/trainingTest.vue"),
    meta: {
      title: "培训课程",
      erify: false,
    },
    redirect: "/trainingTest",
    children: [
      {
        name: "trainingTest",
        path: "/trainingTest",
        component: () => import("@/views/trainingCourse/trainingTest.vue"),
        meta: {
          title: "消防基础知识测试",
          erify: false,
        },
      },
    ],
  },
  {
    //通知公告详细
    name: "noticeAnnouncement",
    path: "/noticeAnnouncement",
    component: () => import("@/views/noticeAnnouncement/noticeDetail.vue"),
    meta: {
      title: "通知公告",
      erify: false,
    },
    redirect: "/noticeDetail",
    children: [
      {
        name: "noticeDetail",
        path: "/noticeDetail",
        component: () => import("@/views/noticeAnnouncement/noticeDetail.vue"),
        meta: {
          title: "通知公告详情",
          erify: false,
        },
      },
    ],
  },
  {
    //个人中心
    name: "personaldata",
    path: "/personaldata",
    component: () => import("@/views/personaldata/index.vue"),
    meta: {
      title: "个人中心",
      erify: false,
    },
  },
  {
    //我的消息
    name: "msg",
    path: "/msg",
    component: () => import("@/views/msg/index.vue"),
    meta: {
      title: "我的消息",
      erify: false,
    },
  },
  {
    //详细内容
    name: "msg",
    path: "/msg",
    component: () => import("@/views/msg/msgDetail.vue"),
    meta: {
      title: "我的消息",
      erify: false,
    },
    redirect: "/msgDetail",
    children: [
      {
        //详细内容
        name: "msgDetail",
        path: "/msgDetail",
        component: () => import("@/views/msg/msgDetail.vue"),
        meta: {
          title: "详细内容",
          erify: false,
        },
      },
    ],
  },
  {
    //直播详细
    name: "liveDetail",
    path: "/liveDetail",
    component: () => import("@/views/trainingCourse/liveDetail.vue"),
    meta: {
      title: "直播详细",
      erify: false,
    },
  },
  {
    //专家库列表页
    name: "expertList",
    path: "/expertList",
    component: () => import("@/views/expertDatabase/index.vue"),
    meta: {
      title: "专家库",
      erify: false,
    },
  },
  {
    //专家详情
    name: "expertList",
    path: "/expertList",
    component: () => import("@/views/expertDatabase/expertDetail.vue"),
    meta: {
      title: "专家库",
      erify: false,
    },
    redirect: "/expertDetail",
    children: [
      {
        //专家详情
        name: "expertDetail",
        path: "/expertDetail",
        component: () => import("@/views/expertDatabase/expertDetail.vue"),
        meta: {
          title: "专家详情",
          erify: false,
        },
      },
    ],
  },
  {
    //在线报名
    name: "onlineCourse",
    path: "/onlineCourse",
    component: () => import("@/views/onlineRegistration/onlineCourse.vue"),
    meta: {
      title: "在线报名",
      erify: false,
    },
  },
  // {
  //   //在线报名选择课程
  //   name: "onlineRegistration",
  //   path: "/onlineRegistration",
  //   component: () => import("@/views/onlineRegistration/onlineCourse.vue"),
  //   meta: {
  //     title: "在线报名",
  //     erify: false,
  //   },
  //   redirect: "/onlineCourse",
  //   children: [
  //     {
  //       name: "onlineCourse",
  //       path: "/onlineCourse",
  //       component: () => import("@/views/onlineRegistration/onlineCourse.vue"),
  //       meta: {
  //         title: "",
  //         erify: false,
  //       },
  //     },
  //   ],
  // },
  {
    //在线报名选择课程
    name: "onlineCourse",
    path: "/onlineCourse",
    component: () => import("@/views/onlineRegistration/index.vue"),
    meta: {
      title: "在线报名",
      erify: false,
    },
    redirect: "/onlineRegistration",
    children: [
      {
        name: "onlineRegistration",
        path: "/onlineRegistration",
        component: () => import("@/views/onlineRegistration/index.vue"),
        meta: {
          title: "在线报名详情",
          erify: false,
        },
      },
    ],
  },
  {
    //在线考试
    name: "onlineExamination",
    path: "/onlineExamination",
    component: () => import("@/views/onlineExamination/index.vue"),
    meta: {
      title: "在线考试",
      erify: false,
    },
  },
  {
    //在线咨询sdk窗口
    name: "indexChart",
    path: "/indexChart",
    component: () => import("@/views/chartWX/indexChart.vue"),
    meta: {
      title: "在线咨询",
      erify: false,
    },
  },
  // {
  //   //公益宣传视频播放页
  //   name: "publicWelfarePublictyVideoPlay",
  //   path: "/publicWelfarePublictyVideoPlay",
  //   component: () =>
  //     import(
  //       "@/views/publicWelfarePublicity/publicWelfarePublictyVideoPlay.vue"
  //     ),
  //   meta: {
  //     title: "公益宣传视频播放",
  //     erify: false,
  //   },
  // },
];

const router = new Router({
  // mode: "history",
  mode: "hash",
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
});
router.beforeEach((to,from,next)=>{
  if(from.name == 'trainingCourse' && to.name == 'basicKnowledge'){
    if(from.meta.keepAlive != true){
      changeKeepAlive('home', 'trainingCourse', true)
      changeKeepAlive('trainingCourse', 'trainingCourse', true)
    }
    next()
  }else if(to.name === 'trainingCourse' && from.name == 'basicKnowledge'){
    console.log('总的陆游',to.meta.keepAlive)
    next()
  }else if(from.name == 'trainingCourse' && to.name != 'basicKnowledge') {

      changeKeepAlive('home', 'trainingCourse', true)
      changeKeepAlive('trainingCourse', 'trainingCourse', true)
      next()
    }else{
      if(from.meta.keepAlive != true){
        changeKeepAlive('home', 'trainingCourse', false)
        changeKeepAlive('trainingCourse', 'trainingCourse', false)
      }
      next()
    }
    
    


})
function  changeKeepAlive (parentName, name, keepAlive) {
  // console.log('剑走偏锋', this.$router.options.routes,keepAlive)
  // @ts-ignore
  router.options.routes?.map((item) => {
    if (item.name === parentName) {
      item.meta.keepAlive = keepAlive
      item.children?.map((a) => {
        if (a.name === name) {
          a.meta.keepAlive = keepAlive
        }
      })
    }
  })
  // let routesLocal = this.$router.options.routes
  // for(let i=0;i<routesLocal.length;i++){
  //   if( routesLocal[i].name == parentName){
  //     for(let j=0; j<routesLocal[i].children.length;j++){
  //       if(routesLocal[i].children[j].name == name){
  //         routesLocal[i].children[j].meta.keepAlive = keepAlive
  //       }
  //     }
  //   }
  // }
}
export default router;
