<template>
  <div id="goTop">
    <div class="goTop" v-show="goTopShow" @click="goTop">
      <i class="el-icon-caret-top goTopIcon"></i>
     <div class="topdiv">
      回到<br/>顶部
     </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Topback",
  data() {
    return {
      scrollTop: "",
      goTopShow: false,
    };
  },
  watch: {
    scrollTop() {
      if (this.scrollTop > 300) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 500) {
        this.goTopShow = true;
      }
    },
    goTop() {
      let timer = null,
        _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 250;
          document.body.scrollTop = document.documentElement.scrollTop =
            _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.goTop {
  position: fixed;
  right: 40px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  /*padding: 10px;*/
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

}
.goTop:hover .goTopIcon {
  color: rgba(197, 6, 18, 1);

}
.goTopIcon {
  font-size: 20px;
  color: #df000f;
  /*margin: auto;*/

}
.topdiv{
  /*margin-left: 4px;*/
  /*  margin-bottom: 7px;*/
    color: #df000f;
}
.topdiv:hover {
  color: rgba(197, 6, 18, 1);
}
</style>
