import Vue from 'vue'
import App from './App.vue'

import router from './router/index';
import store from './store'

import {getYearMonth,transNumber,getYearMonth2} from './common/time.js'
import clipboard from 'clipboard'
Vue.prototype.clipboard = clipboard

import VueCookie from 'vue-cookies'
Vue.use(VueCookie)



// 视频播放
// import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// Vue.use(VideoPlayer)

import {
  Form,
  FormItem,
  Button,
  Carousel,
  CarouselItem,
  Pagination,
  Slider,
  Radio,
  RadioGroup,
  Divider,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Col,
  Row,
  Input,
  Card,
  Message,
  Breadcrumb,
  BreadcrumbItem,
  Submenu,
  Menu,
  MenuItem,
  Step,
  Steps,
  Select,
  Option,
  Tabs,
  TabPane,
  Loading 
} from 'element-ui';

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Slider)
Vue.use(Radio)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioGroup)
Vue.use(Divider)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Input)
Vue.use(Card)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading)






Vue.config.productionTip = false
Vue.prototype.$message= Message
Vue.prototype.$getYearMonth = getYearMonth
// 全局过滤器，格式化时间戳用
Vue.filter("dataChange", (data)=>{
  return Vue.prototype.$getYearMonth(data)
})
Vue.filter("dataChange2", (data)=>{
  return getYearMonth2(data)
})
Vue.filter("transNumber", (data)=>{
  return transNumber(data)
})

new Vue({
  router,
  render: h => h(App),
  store,
  created(){
    let token = this.$cookies.get('token');
    var user = this.$cookies.get('user');
    if(token && user){
        this.$store.commit('getUser', user);
        this.$store.state.isLogin = true;
    }
}
}).$mount('#app')